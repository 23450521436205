var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.renderComponent
    ? _c("div", { staticClass: "custom-checkbox-list" }, [
        _c("div", { staticClass: "item" }, [
          _c(
            "div",
            { staticClass: "item-content" },
            _vm._l(_vm.categoryList, function (i) {
              return _c(
                "div",
                { staticClass: "item-row m-top-25" },
                [
                  _c(
                    "div",
                    { staticClass: "with-dropdown" },
                    [
                      _c("CustomCheckbox", {
                        staticClass: "main-categories item-item-check m-top-10",
                        attrs: {
                          id: "list-checkbox-item-all",
                          label: i.categoryName
                            ? _vm.$t("Permission.Categories_" + i.categoryName)
                            : i.name,
                          checked:
                            (_vm.isAdmin && i.hasPermissison) ||
                            _vm.isCheckAll(i) ||
                            i.isCategoryCheck,
                          disabled: _vm.isAdmin && !i.isEditableAdmin,
                        },
                        on: {
                          change: (isChecked) => _vm.checkAll(isChecked, i),
                        },
                      }),
                      _vm.isListGroup
                        ? _c(
                            "svg",
                            {
                              staticClass: "icon-dropdown-custom",
                              class: i.isDropdownVisible ? "rotateArrow" : "",
                              on: {
                                click: function ($event) {
                                  return _vm.toggleDropdown(i)
                                },
                              },
                            },
                            [
                              _c("use", {
                                attrs: { "xlink:href": "#icon-arrow-down" },
                              }),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._l(
                    i.permissionList ||
                      i.reports ||
                      _vm.subList ||
                      i.listPermissionList ||
                      i.pricePermissionList,
                    function (item) {
                      return _c(
                        "div",
                        { staticClass: "item-row permissions" },
                        [
                          _c(
                            "div",
                            { staticClass: "with-dropdown" },
                            [
                              !_vm.isListGroup ||
                              (_vm.isListGroup && i.isDropdownVisible)
                                ? _c("CustomCheckbox", {
                                    staticClass: "item-item-check m-top-20",
                                    attrs: {
                                      id: "list-checkbox-item-all",
                                      label: item.permissionKey
                                        ? _vm.$t(
                                            "Permission.Menu_" +
                                              item.permissionKey
                                          )
                                        : item.name,
                                      checked:
                                        (_vm.isAdmin && item.hasPermissison) ||
                                        item.isCheck,
                                      disabled:
                                        _vm.isAdmin && !item.isEditableAdmin,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.selectItem(item)
                                      },
                                    },
                                  })
                                : _vm._e(),
                              item.permissionListHasValue
                                ? _c(
                                    "svg",
                                    {
                                      staticClass: "icon-dropdown",
                                      class: item.isDropdownVisible
                                        ? "rotateArrow"
                                        : "",
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggleDropdown(item)
                                        },
                                      },
                                    },
                                    [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href": "#icon-arrow-down",
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._l(item.permissionList, function (subItem) {
                            return _c(
                              "div",
                              { staticClass: "item-row permissions" },
                              [
                                item.isDropdownVisible &&
                                !/^(DefineSupplierAsGlobal|DefineSupplierAsLocal)$/.test(
                                  subItem.permissionKey
                                )
                                  ? _c("CustomCheckbox", {
                                      staticClass: "item-item-check m-top-20",
                                      attrs: {
                                        id: "list-checkbox-item-all",
                                        label: _vm.$t(
                                          "Permission.Menu_" +
                                            subItem.permissionKey
                                        ),
                                        checked:
                                          item.isCheck && subItem.isCheck,
                                        disabled: !item.isCheck,
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.selectItem(subItem)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                item.isDropdownVisible &&
                                /^(DefineSupplierAsGlobal|DefineSupplierAsLocal)$/.test(
                                  subItem.permissionKey
                                )
                                  ? _c("CustomRadio", {
                                      staticClass: "item-item-check m-top-20",
                                      attrs: {
                                        label: _vm.$t(
                                          "Permission.Menu_" +
                                            subItem.permissionKey
                                        ),
                                        checked: _vm.checkGlobalSupplier(
                                          subItem,
                                          item
                                        ),
                                        disabled: !item.isCheck,
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.selectItem(
                                            subItem,
                                            item.permissionList
                                          )
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      )
                    }
                  ),
                ],
                2
              )
            }),
            0
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }