<template lang="pug">
  Popup

    template(slot="title")
      template(v-if="isEdit") {{ $t('Authorization.popupForm_Title_Edit') }}
      template(v-else) {{ $t('Authorization.popupForm_Title_Create') }}
    div(v-if="isLoading")
    template(v-else slot="content")
      form.Form(
      id="form-user-group"
      @submit.prevent="onSubmitUserGroupForm")

        .Form-item
          label.Form-item-label {{ $t('Authorization.popupForm_Field_Name') }}
          .control
            customTextInput(
              id="input-user-groups-popup-field-name"
              ref="firstField"
              v-model="item.name"
              v-validate="'required|max:128|verify_name'"
              name="name"
              :data-vv-as="$t('Authorization.popupForm_Field_Name')"
              :error="veeErrors.has('name')"
              :disabled="isAdmin"
            )

            Button(
            id="btn-user-groups-popup-loading-name"
            type="button",
            variant="icon loading",
            tabindex="-1",
            v-show="isPendingName")
              Loading(theme="disable")
            Button(
            id="btn-user-groups-popup-ok-name"
            type="button",
            variant="icon check",
            tabindex="-1",
            v-show="isValidateName")
              svg.icon.icon-check
                use(xlink:href="#icon-check")

            showValidateError(
              v-show="veeErrors.has('name')"
              :errorName="veeErrors.first('name')"
            )

        .Form-item

          customSelect(
            id="user-groups-select-gorup"
            componentName="select-group-for-user-groups"
            :optionData="tabs"
            v-model="activeTab"
            :isDefaultTextActive="false"
          )

        .Form-item(v-if="tabIsActive($t('Authorization.userGroups_Tab'))")
          CustomCheckboxList(
            :categoryList="permissionRoleList"
            :isAdmin="isAdmin"
            @selectItems="selectPermissionListItems"
            @selectAll="selectAllPermissionListItems"
          )

        .Form-item(v-if="tabIsActive($t('Authorization.recipeGroups_Tab'))")
          CustomCheckboxList(
            :categoryList="recipeGroupList"
            :isAdmin="isAdmin"
            @selectAll="selectAllRecipeListItems"
          )

        .Form-item(v-if="tabIsActive($t('Authorization.reportGroups_Tab'))")

          CustomCheckboxList(
            :categoryList="reportGroupList"
            :isAdmin="isAdmin"
            @selectItems="selectReportListItems"
            @selectAll="selectAllReportListItems"
          )
        .Form-item(v-if="tabIsActive($t('Authorization.listGroups_Tab'))")
          CustomCheckboxList(
            :categoryList="listGroupList"
            :isAdmin="isAdmin"
            :isListGroup="true"
            @selectItems="selectListItems"
            @selectAll="selectAllListItems"
          )
        .Form-item(v-if="tabIsActive($t('Authorization.priceList_Tab'))")
          CustomCheckboxList(
            :categoryList="priceList"
            :isAdmin="isAdmin"
            @selectItems="selectPriceItems"
            @selectAll="selectAllPriceItems"
          )

    template(slot="footer")
      template(v-if="isEdit")
        .multi-footer
          Button(
          id="btn-recipes-popup-remove"
          type="button",
          danger
          variant="icon"
          size="medium"
          @click="openRemovePopup")
            svg.icon.icon-delete
              use(xlink:href="#icon-delete")
          Button(
          id="btn-user-groups-popup-submit-edit"
          type="submit"
          variant="full"
          size="medium"
          primary
          form="form-user-group"
          :disabled="isLoading")
            span(v-show="!isLoading") {{ $t('Authorization.popupForm_Button_Edit') }}
            Loading(theme="disable", v-show="isLoading")
      template(v-else)
        Button(
        id="btn-user-groups-popup-submit-new"
        type="submit"
        primary
        size="medium"
        variant="full"
        form="form-user-group"
        :disabled="isLoading")
          span(v-show="!isLoading") {{ $t('Authorization.popupForm_Button_Create') }}
          Loading(theme="disable", v-show="isLoading")

</template>

<script>
import { mapFields, Validator } from 'vee-validate'
import { mapActions } from 'vuex'
import customSelect from '@/view/global/custom-select'
import CustomCheckboxList from '../components/custom-checkbox-list.vue'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'UserGroupForm',

  props: {
    status: {
      type: String,
      default: 'new'
    }
  },

  components: {
    customSelect,
    CustomCheckboxList
  },

  data () {
    return {
      item: {},
      reportGroupList: [],
      permissionRoleList: [],
      recipeGroupList: [],
      listGroupList: [],
      selectedItems: [],
      selectedRecipeItems: [],
      selectedReportItems: [],
      selectedListItems: [],
      priceList: [],
      selectedPriceItems: [],

      activeTab: this.$t('Authorization.userGroups_Tab'),
      tabs: [
        this.$t('Authorization.userGroups_Tab'),
        this.$t('Authorization.recipeGroups_Tab'),
        this.$t('Authorization.reportGroups_Tab'),
        this.$t('Authorization.listGroups_Tab'),
        this.$t('Authorization.priceList_Tab')
      ]
    }
  },

  beforeMount () {
    const self = this
    const verifyName = {
      getMessage (field, params, data) {
        return (data && data.message) || 'Something wrong'
      },
      validate (value) {
        return new Promise(resolve => {
          let params = {
            Name: value,
            Id: self.$route.params.id
          }
          self.checkUserRoleName(params)
            .then(res => {
              if (res) {
                resolve({
                  valid: res.data.isAvailable
                })
              } else {
                resolve({
                  valid: false
                })
              }
            })
        })
      }
    }

    const verifyNameMessages = {
      tr: {
        messages: {
          verify_name: field => this.$t('Authorization.ValidErrorForUserGroupName')
        }
      }
    }

    Validator.extend('verify_name', verifyName)
    Validator.localize(verifyNameMessages)
  },

  async created () {
    if (this.isEdit) {
      await this.init()
      await this.setFormData()
      this.formFocus()
    } else {
      await this.setFormData()
    }
  },

  computed: {
    ...mapFields({
      flagsName: 'name'
    }),

    isLoading () {
      return this.$wait.is(['updateUserGroup', 'createUserGroup', 'onSubmitUserGroups'])
    },

    isEdit () {
      return this.$route.params.status === 'edit'
    },

    isAdmin () {
      return this.$route.params.userGroupName === 'Admin'
    },

    isPendingName () {
      return this.flagsName.pending
    },

    isValidateName () {
      if (this.flagsName.required) {
        return this.flagsName.valid &&
          !this.isPendingName
      } else {
        return this.flagsName.valid &&
          this.item.name !== '' &&
          this.item.name !== null &&
          !this.isPendingName
      }
    }
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('RecipeGroups', [
      'get_RECIPEGROUP_LIST'
    ]),

    ...mapActions('Settings', [
      'fetchUserGroupList',
      'fetchUserGroup',
      'createUserGroup',
      'updateUserGroup',
      'deleteUserGroup',
      'fetchPermissionRoleList',
      'getPermissionRoleList',
      'checkUserRoleName',
      'fetchUserList'
    ]),

    tabIsActive (tab) {
      return this.activeTab === tab
    },

    async init () {
      let item = this.$route.params.item
      if (!item) {
        let itemId = this.$route.params.id
        await this.fetchUserGroup(itemId).then(res => {
          if (res.status === 200) {
            item = res.data.role
          }
        })
      }
      this.item = item
    },

    setFormData() {
      this.fetchPermissionRoleList(this.item.id).then(res => {
        this.permissionRoleList = res.data.permissionCategoryList
        this.recipeGroupList = res.data.recipeGroupPermissionList
        if (this.isEdit) {
          this.recipeGroupList.forEach(i => {
            if (i.hasPermission) {
              i.isCategoryCheck = true
              this.selectedRecipeItems.push(i.id)
            }
          })
        } else {
          this.get_RECIPEGROUP_LIST()
          .then(result => {
            this.recipeGroupList = result.data.recipeGroupList
          })
        }
        this.reportGroupList = res.data.reportDefinitionPermissionList
        if (!this.isEdit) {
          this.getPermissionRoleList(this.item.id).then(res => {
            this.listGroupList = res.data.listPermissionCategoryList
          })
        } else this.listGroupList = res.data.listPermissionCategoryList
        this.priceList = res.data.pricePermissionCategoryList

        this.permissionRoleList.map(item => {
          item.permissionList.forEach(i => {
            if (i.hasPermissison) {
              i.isCheck = true
              this.selectedItems.push(i.permission)
            }
            if (i.permissionListHasValue) {
              if (i.permissionKey === 'SuppliersMenu' || 'ListMenu') {
                i.permissionList.map(i => {
                  if (i.hasPermissison) {
                    i.isCheck = true
                    this.selectedItems.push(i.permission)
                  }
                })
              }
            } else {
              i.permissionList.map(i => {
                i.isCheck = true
                this.selectedItems.push(i.permission)
              })
            }
          })
        })

        this.reportGroupList = res.data.reportDefinitionPermissionList
        this.reportGroupList.map(item => {
          item.reports.forEach(i => {
            if (i.hasPermission) {
              i.isCheck = true
              this.selectedReportItems.push(i.id)
            }
          })
        })

        this.listGroupList.map(item => {
          item.listPermissionList.forEach(i => {
            if (i.hasPermission) {
              i.isCheck = true
              this.selectedListItems.push(i)
            }
          })
        })

        this.priceList.map(item => {
          item.pricePermissionList.forEach(i => {
            if (i.hasPermissison) {
              i.isCheck = true
              this.selectedPriceItems.push(i.permission)
            }
          })
        })
      })
    },

    close () {
      this.$router.push({
        name: 'Authorization'
      })
    },

    openRemovePopup () {
      this.$router.push({
        name: 'ShowUserGroupRemove',
        params: {
          item: this.item,
          id: this.item.id
        }
      })
    },

    selectPermissionListItems (item, permissionList) {
      if (item.permissionKey === 'DefineSupplierAsGlobal' || item.permissionKey === 'DefineSupplierAsLocal') {
        if (item.permission === 31) {
          if (!item.isCheck) item.isCheck = true
          permissionList.find(i => {
            if (i.permission === 32) {
              i.isCheck = false
              this.selectedItems.splice(this.selectedItems.indexOf(32), 1)
            }
          }) 
        }
        if (item.permission === 32) {
          if (!item.isCheck) item.isCheck = true
          permissionList.find(i => {
            if (i.permission === 31) {
              i.isCheck = false
              this.selectedItems.splice(this.selectedItems.indexOf(31), 1)
            }
          })
        }
      } else item.isCheck = !item.isCheck

      if (item.isCheck) {
        this.selectedItems.push(item.permission)
      } else this.selectedItems.splice(this.selectedItems.indexOf(item.permission), 1)
    },

    selectAllPermissionListItems ({isChecked, item}) {
      if (isChecked) {
        item.permissionList.forEach(i => {
          if (i.isCheck) return
          i.isCheck = true
          this.selectedItems.push(i.permission)
        })
      } else {
        item.permissionList.forEach(i => {
          i.isCheck = false
          this.selectedItems.splice(this.selectedItems.indexOf(i.permission), 1)
        })
      }
    },

    selectReportListItems (item) {
      item.isCheck = !item.isCheck
      if (item.isCheck === true) this.selectedReportItems.push(item.id)
      else this.selectedReportItems.splice(this.selectedReportItems.indexOf(item.id), 1)
    },

    selectAllReportListItems ({isChecked, item}) {
      if (isChecked) {
        item.reports.forEach(i => {
          if (i.isCheck) return
          i.isCheck = true
          this.selectedReportItems.push(i.id)
        })
      } else {
        item.reports.forEach(i => {
          i.isCheck = false
          this.selectedReportItems.splice(this.selectedReportItems.indexOf(i.id), 1)
        })
      }
    },

    selectAllRecipeListItems ({isChecked, item}) {
      item.isCategoryCheck = !item.isCategoryCheck
      if (item.isCategoryCheck === true) this.selectedRecipeItems.push(item.id)
      else this.selectedRecipeItems.splice(this.selectedRecipeItems.indexOf(item), 1)
    },

    selectListItems (item) {
      item.isCheck = !item.isCheck
      if (item.isCheck === true) this.selectedListItems.push(item)
      else this.selectedListItems.splice(this.selectedListItems.indexOf(item), 1)
    },

    selectPriceItems (item) {
      item.isCheck = !item.isCheck
      if (item.isCheck) this.selectedPriceItems.push(item.permission)
      else this.selectedPriceItems.splice(this.selectedPriceItems.indexOf(item.permission), 1)
    },
    selectAllPriceItems ({isChecked, item}) {
      if (isChecked) {
        item.pricePermissionList.forEach(i => {
          if (i.isCheck) return
          i.isCheck = true
          this.selectedPriceItems.push(i.permission)
        })
      } else {
        item.pricePermissionList.forEach(i => {
          i.isCheck = false
          this.selectedPriceItems.splice(this.selectedPriceItems.indexOf(i.permission), 1)
        })
      }
    },

    selectAllListItems ({isChecked, item}) {
      if (isChecked) {
        item.listPermissionList.forEach(i => {
          if (i.isCheck) return
          i.isCheck = true
          this.selectedListItems.push(i)
        })
      } else {
        item.listPermissionList.forEach(i => {
          i.isCheck = false
          this.selectedListItems.splice(this.selectedListItems.indexOf(i), 1)
        })
      }
    },

    formFocus () {
      this.$refs.firstField.focusCustomInput()
    },

    onSubmitUserGroupForm: vueWaitLoader(async function () {
      await this.$validator.validateAll().then(result => {
        // form error
        if (!result) return

        if (this.isEdit) {
          return this.submitEdit()
        }
        return this.submitNew()
      })
    }, 'onSubmitUserGroups'),

    async submitNew () {
      const permissionList = this.selectedItems
      const recipeGroupIdList = this.selectedRecipeItems
      const reportDefinitionIdList = this.selectedReportItems
      const pricePermissionList = this.selectedPriceItems
      const listIdList = []
      this.selectedListItems.map(i => {
        listIdList.push({id: i.id, type: i.category})
      })
      await this.createUserGroup({ name: this.item.name, permissionList, recipeGroupIdList, reportDefinitionIdList, listIdList, pricePermissionList }).then(res => {
        if (res) {
          const message = this.$t('Global.notification_Created', { name: this.item.name })
          this.notifyShow({ message })
          this.$emit('getUserList')
          this.close()
        }
      })
    },

    async submitEdit () {
      const permissionList = this.selectedItems
      const recipeGroupIdList = this.selectedRecipeItems
      const reportDefinitionIdList = this.selectedReportItems
      const pricePermissionList = this.selectedPriceItems
      const listIdList = []
      this.selectedListItems.map(i => {
        listIdList.push({id: i.id, type: i.category})
      })

      await this.updateUserGroup({
        id: this.item.id,
        name: this.item.name,
        permissionList,
        recipeGroupIdList,
        reportDefinitionIdList,
        listIdList,
        pricePermissionList
      }).then(res => {
        if (res) {
          const message = this.$t('Global.notification_Updated', { name: this.item.name })
          this.notifyShow({ message })
          this.$emit('getUserList')
          this.close()
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>

.inline-tab {
  display: flex;
  border-bottom: 1px solid $color-gray;

  &-item {
    display: flex;
    justify-content: center;
    cursor: pointer;
  }

  &-label {
    cursor: pointer;
    margin: 10px;
    margin-right: 30px;
  }

  .isActive {
    color: $color-dark;
    font-weight: $font-weight-bold;
    border-bottom: 3px solid $color-primary;
  }
}
  :deep() .select-dropdown-custom-selected {
    width: 100% !important;
    height: 54px !important;
  }
  :deep() .select-dropdown-custom-options {
    width: 100% !important;
  }
</style>
