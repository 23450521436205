export default function getFields($t) {
  return [
    {
      name: 'status',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('Authorization.popupUserForm_Field_isActive')
      },
      get title() {
        return $t('Authorization.popupUserForm_Field_isActive')
      }
    },
    {
      name: 'firstname',
      is_visible: true,
      get short_title() {
        return $t('Authorization.popupUserForm_Field_Name')
      },
      get title() {
        return $t('Authorization.popupUserForm_Field_Name')
      }
    },
    {
      name: 'surname',
      is_visible: true,
      get short_title() {
        return $t('Authorization.popupUserForm_Field_Surname')
      },
      get title() {
        return $t('Authorization.popupUserForm_Field_Surname')
      }
    },
    {
      name: 'emailAddress',
      is_visible: true,
      type: 'portal',
      get short_title() {
        return $t('Authorization.popupUserForm_Field_Email')
      },
      get title() {
        return $t('Authorization.popupUserForm_Field_Email')
      }
    },
    {
      name: 'role',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('Authorization.popupForm_Field_UserGroups')
      },
      get title() {
        return $t('Authorization.popupForm_Field_UserGroups')
      }
    },
    {
      name: 'defaultStoreName',
      is_visible: true,
      get short_title() {
        return $t('Authorization.Default_Store_Label')
      },
      get title() {
        return $t('Authorization.Default_Store_Label')
      }
    },
    {
      name: 'approvalLevel',
      is_visible: true,
      get short_title() {
        return $t('Global.approvalLevel')
      },
      get title() {
        return $t('Global.approvalLevel')
      }
    }
  ]
}
