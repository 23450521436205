<template lang="pug">
DeleteAlert(
  :item="item",
  :fields="fields",
  :tableName="$t('Authorization.remove_TableName')",
  loading="onRemoveUserGroup",
  @delegateOnAlertRemoveOk="itemRemove")
</template>

<script>
import { mapActions } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'GroupRemove',

  data () {
    return {
      fields: [{
        name: 'name',
        is_visible: true,
        short_title: this.$t('Authorization.remove_TableColumn_Name_Short'),
        title: this.$t('Authorization.remove_TableColumn_Name')
      }],
      item: {}
    }
  },

  async mounted () {
    this.item = this.$route.params.item
    if (this.$route.params.item) {
      this.item = this.$route.params.item
    } else {
      this.fetchUserGroup(this.$route.params.id)
        .then(res => {
          this.item = res.data.role
        })
    }
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Settings', [
      'fetchUserGroupList',
      'deleteUserGroup',
      'fetchUserGroup'
    ]),

    close () {
      this.$router.push({
        name: 'Authorization'
      })
    },

    itemRemove: vueWaitLoader(async function () {
      await this.deleteUserGroup(this.item.id).then(res => {
        if (res) {
          const message = this.$t('Global.notification_Deleted', { name: this.item.name })
          this.notifyShow({ message })
          this.$emit('getUserList')
          this.close()
        }
      })
    }, 'onRemoveUserGroup')
  }
}
</script>

<style lang="scss">
</style>
