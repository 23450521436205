var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c(
        "template",
        { slot: "title" },
        [
          _vm.isEdit
            ? [_vm._v(_vm._s(_vm.$t("Authorization.popupForm_Title_Edit")))]
            : [_vm._v(_vm._s(_vm.$t("Authorization.popupForm_Title_Create")))],
        ],
        2
      ),
      _vm.isLoading
        ? _c("div")
        : _c("template", { slot: "content" }, [
            _c(
              "form",
              {
                staticClass: "Form",
                attrs: { id: "form-user-group" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.onSubmitUserGroupForm.apply(null, arguments)
                  },
                },
              },
              [
                _c("div", { staticClass: "Form-item" }, [
                  _c("label", { staticClass: "Form-item-label" }, [
                    _vm._v(
                      _vm._s(_vm.$t("Authorization.popupForm_Field_Name"))
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "control" },
                    [
                      _c("customTextInput", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|max:128|verify_name",
                            expression: "'required|max:128|verify_name'",
                          },
                        ],
                        ref: "firstField",
                        attrs: {
                          id: "input-user-groups-popup-field-name",
                          name: "name",
                          "data-vv-as": _vm.$t(
                            "Authorization.popupForm_Field_Name"
                          ),
                          error: _vm.veeErrors.has("name"),
                          disabled: _vm.isAdmin,
                        },
                        model: {
                          value: _vm.item.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.item, "name", $$v)
                          },
                          expression: "item.name",
                        },
                      }),
                      _c(
                        "Button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isPendingName,
                              expression: "isPendingName",
                            },
                          ],
                          attrs: {
                            id: "btn-user-groups-popup-loading-name",
                            type: "button",
                            variant: "icon loading",
                            tabindex: "-1",
                          },
                        },
                        [_c("Loading", { attrs: { theme: "disable" } })],
                        1
                      ),
                      _c(
                        "Button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isValidateName,
                              expression: "isValidateName",
                            },
                          ],
                          attrs: {
                            id: "btn-user-groups-popup-ok-name",
                            type: "button",
                            variant: "icon check",
                            tabindex: "-1",
                          },
                        },
                        [
                          _c("svg", { staticClass: "icon icon-check" }, [
                            _c("use", {
                              attrs: { "xlink:href": "#icon-check" },
                            }),
                          ]),
                        ]
                      ),
                      _c("showValidateError", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("name"),
                            expression: "veeErrors.has('name')",
                          },
                        ],
                        attrs: { errorName: _vm.veeErrors.first("name") },
                      }),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "Form-item" },
                  [
                    _c("customSelect", {
                      attrs: {
                        id: "user-groups-select-gorup",
                        componentName: "select-group-for-user-groups",
                        optionData: _vm.tabs,
                        isDefaultTextActive: false,
                      },
                      model: {
                        value: _vm.activeTab,
                        callback: function ($$v) {
                          _vm.activeTab = $$v
                        },
                        expression: "activeTab",
                      },
                    }),
                  ],
                  1
                ),
                _vm.tabIsActive(_vm.$t("Authorization.userGroups_Tab"))
                  ? _c(
                      "div",
                      { staticClass: "Form-item" },
                      [
                        _c("CustomCheckboxList", {
                          attrs: {
                            categoryList: _vm.permissionRoleList,
                            isAdmin: _vm.isAdmin,
                          },
                          on: {
                            selectItems: _vm.selectPermissionListItems,
                            selectAll: _vm.selectAllPermissionListItems,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.tabIsActive(_vm.$t("Authorization.recipeGroups_Tab"))
                  ? _c(
                      "div",
                      { staticClass: "Form-item" },
                      [
                        _c("CustomCheckboxList", {
                          attrs: {
                            categoryList: _vm.recipeGroupList,
                            isAdmin: _vm.isAdmin,
                          },
                          on: { selectAll: _vm.selectAllRecipeListItems },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.tabIsActive(_vm.$t("Authorization.reportGroups_Tab"))
                  ? _c(
                      "div",
                      { staticClass: "Form-item" },
                      [
                        _c("CustomCheckboxList", {
                          attrs: {
                            categoryList: _vm.reportGroupList,
                            isAdmin: _vm.isAdmin,
                          },
                          on: {
                            selectItems: _vm.selectReportListItems,
                            selectAll: _vm.selectAllReportListItems,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.tabIsActive(_vm.$t("Authorization.listGroups_Tab"))
                  ? _c(
                      "div",
                      { staticClass: "Form-item" },
                      [
                        _c("CustomCheckboxList", {
                          attrs: {
                            categoryList: _vm.listGroupList,
                            isAdmin: _vm.isAdmin,
                            isListGroup: true,
                          },
                          on: {
                            selectItems: _vm.selectListItems,
                            selectAll: _vm.selectAllListItems,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.tabIsActive(_vm.$t("Authorization.priceList_Tab"))
                  ? _c(
                      "div",
                      { staticClass: "Form-item" },
                      [
                        _c("CustomCheckboxList", {
                          attrs: {
                            categoryList: _vm.priceList,
                            isAdmin: _vm.isAdmin,
                          },
                          on: {
                            selectItems: _vm.selectPriceItems,
                            selectAll: _vm.selectAllPriceItems,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
          ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _vm.isEdit
            ? [
                _c(
                  "div",
                  { staticClass: "multi-footer" },
                  [
                    _c(
                      "Button",
                      {
                        attrs: {
                          id: "btn-recipes-popup-remove",
                          type: "button",
                          danger: "",
                          variant: "icon",
                          size: "medium",
                        },
                        on: { click: _vm.openRemovePopup },
                      },
                      [
                        _c("svg", { staticClass: "icon icon-delete" }, [
                          _c("use", {
                            attrs: { "xlink:href": "#icon-delete" },
                          }),
                        ]),
                      ]
                    ),
                    _c(
                      "Button",
                      {
                        attrs: {
                          id: "btn-user-groups-popup-submit-edit",
                          type: "submit",
                          variant: "full",
                          size: "medium",
                          primary: "",
                          form: "form-user-group",
                          disabled: _vm.isLoading,
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.isLoading,
                                expression: "!isLoading",
                              },
                            ],
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("Authorization.popupForm_Button_Edit")
                              )
                            ),
                          ]
                        ),
                        _c("Loading", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isLoading,
                              expression: "isLoading",
                            },
                          ],
                          attrs: { theme: "disable" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : [
                _c(
                  "Button",
                  {
                    attrs: {
                      id: "btn-user-groups-popup-submit-new",
                      type: "submit",
                      primary: "",
                      size: "medium",
                      variant: "full",
                      form: "form-user-group",
                      disabled: _vm.isLoading,
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isLoading,
                            expression: "!isLoading",
                          },
                        ],
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("Authorization.popupForm_Button_Create")
                          )
                        ),
                      ]
                    ),
                    _c("Loading", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isLoading,
                          expression: "isLoading",
                        },
                      ],
                      attrs: { theme: "disable" },
                    }),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }