var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "custom-list-checkbox" }, [
      _c(
        "div",
        { staticClass: "item-content" },
        [
          _c(
            "div",
            { staticClass: "MiniPopup-tr item-row" },
            [
              _c("CustomCheckbox", {
                staticClass: "item-item-check",
                attrs: {
                  id: "list-checkbox-select-all",
                  checked: _vm.isAllSelected,
                  label: _vm.allLabel,
                  disabled: _vm.disabled,
                },
                on: { change: _vm.toggleAll },
              }),
            ],
            1
          ),
          _vm._l(_vm.items, function (item, index) {
            return item.isSearch
              ? _c(
                  "div",
                  { staticClass: "MiniPopup-tr item-row" },
                  [
                    _c("CustomCheckbox", {
                      staticClass: "item-item-check",
                      attrs: {
                        id: `list-checkbox-item-${_vm.id}-${index}`,
                        checked: item.isChecked,
                        label: item.name,
                        disabled: _vm.disabled,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.changeSelectbox(item)
                        },
                      },
                    }),
                    item.isSearch &&
                    item.defaultStore &&
                    item.isChecked &&
                    _vm.withStar
                      ? _c(
                          "Button",
                          {
                            attrs: {
                              type: "button",
                              size: "small",
                              clearVariant: true,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.changeDefaultStore(item)
                              },
                            },
                          },
                          [
                            _c(
                              "svg",
                              { staticClass: "icon-vote-star-filled" },
                              [
                                _c("use", {
                                  attrs: {
                                    "xlink:href": "#icon-vote-star-filled",
                                  },
                                }),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    item.isChecked && !item.defaultStore && _vm.withStar
                      ? _c(
                          "Button",
                          {
                            attrs: {
                              type: "button",
                              size: "small",
                              clearVariant: true,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.changeDefaultStore(item)
                              },
                            },
                          },
                          [
                            _c("svg", { staticClass: "icon-vote-star-empty" }, [
                              _c("use", {
                                attrs: {
                                  "xlink:href": "#icon-vote-star-empty",
                                },
                              }),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e()
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }