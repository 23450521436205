import BaseClass from '@/utils/baseclass'

export class UserGroups extends BaseClass {
  addItem(item) {
    let newItem = new UserGroup(item)
    this.list.push(newItem)
  }

  updateItem(item) {
    let findItem = this.findItem(item.id)
    if (findItem) {
      findItem.id = item.id
      findItem.name = item.name
      findItem.isReadOnly = item.isReadOnly
    }
  }
}

class UserGroup {
  constructor(item) {
    this.id = item.id
    this.name = item.name
    this.isReadOnly = item.isReadOnly
  }
}

export class Users extends BaseClass {
  addItem(item) {
    let newItem = new User(item)
    this.list.push(newItem)
  }

  updateItem(item) {
    let findItem = this.findItem(item.id)
    if (findItem) {
      findItem.emailAddress = item.emailAddress
      findItem.id = item.id
      findItem.firstname = item.firstname
      findItem.surname = item.surname
      findItem.isActive = item.isActive
      findItem.type = item.type
      findItem.defaultStoreId = item.defaultStoreId
      findItem.defaultStoreName = item.defaultStoreName
      findItem.hasPermissionToAllStores = item.hasPermissionToAllStores
      findItem.hasPermissionToAllTransferRequestStores = item.hasPermissionToAllTransferRequestStores
      findItem.approvalLevel = item.approvalLevel
      findItem.isOutOfOffice = item.isOutOfOffice
      findItem.emailConfirmed = item.emailConfirmed
      findItem.phoneNumber = item.phoneNumber
    }
  }
}

export class Stores extends BaseClass {
  addItem(item) {
    let newItem = new Store(item)
    this.list.push(newItem)
  }

  updateItem(item) {
    let findItem = this.findItem(item.id)
    if (findItem) {
      findItem.name = item.name
      findItem.id = item.id
      findItem.defaultStore = item.defaultStore
      findItem.isChecked = item.isChecked
      findItem.isSearch = item.isSearch
    }
  }
}

class User {
  constructor(item) {
    this.emailAddress = item.emailAddress
    this.id = item.id
    this.firstname = item.firstname
    this.surname = item.surname
    this.isActive = item.isActive
    this.type = item.type
    this.defaultStoreId = item.defaultStoreId
    this.defaultStoreName = item.defaultStoreName
    this.hasPermissionToAllStores = item.hasPermissionToAllStores
    this.hasPermissionToAllTransferRequestStores = item.hasPermissionToAllTransferRequestStores
    this.approvalLevel = item.approvalLevel
    this.isOutOfOffice = item.isOutOfOffice
    this.phoneNumber = item.phoneNumber
  }
}

class Store {
  constructor(item) {
    this.name = item.name
    this.id = item.id
    this.defaultStore = false
    this.isChecked = true
    this.isSearch = true // is have in search request
  }
}
