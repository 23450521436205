import Index from '../'
import UserGroupForm from '../popup/user-groups.vue'
import ShowUserGroupRemove from '../popup/remove-user-group'
import UserForm from '../popup/user.vue'

export default [
  {
    meta: {
      main_menu: true,
      category: 'Governance',
      permissionKey: 'AuthorizationMenu',
      page: 'authorization',
      slug: 'authorization'
    },
    path: '/authorization',
    name: 'Authorization',
    component: Index,
    children: [
      {
        path: 'new-user-group',
        name: 'NewUserGroups',
        component: UserGroupForm
      },
      {
        path: 'user-group/:status/:userGroupName/:id/',
        name: 'EditUserGroups',
        component: UserGroupForm
      },
      {
        path: ':id/remove-user-group',
        name: 'ShowUserGroupRemove',
        component: ShowUserGroupRemove
      },
      {
        path: 'new-user',
        name: 'NewUser',
        component: UserForm
      },
      {
        path: ':id/edit-user',
        name: 'EditUser',
        component: UserForm,
        props: {
          status: 'edit'
        }
      }
    ]
  }
]
