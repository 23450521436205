<template lang="pug">
.custom-checkbox-list(v-if="renderComponent")
  .item
    .item-content
      .item-row(v-for="i in categoryList").m-top-25
        .with-dropdown
          CustomCheckbox.main-categories.item-item-check.m-top-10(
            id="list-checkbox-item-all"
            :label="i.categoryName ? $t('Permission.Categories_'+i.categoryName) : i.name"
            :checked="isAdmin && i.hasPermissison || isCheckAll(i) || i.isCategoryCheck"
            :disabled="isAdmin && !i.isEditableAdmin"
            @change="isChecked => checkAll(isChecked, i)"
          )
          svg.icon-dropdown-custom(v-if="isListGroup" :class="i.isDropdownVisible ? 'rotateArrow' : ''" @click="toggleDropdown(i)")
            use(xlink:href="#icon-arrow-down")
        .item-row.permissions(v-for="item in (i.permissionList || i.reports || subList || i.listPermissionList || i.pricePermissionList)")
          .with-dropdown
            CustomCheckbox.item-item-check.m-top-20(
              id="list-checkbox-item-all"
              :label="item.permissionKey ? $t('Permission.Menu_'+item.permissionKey) : item.name"
              :checked="isAdmin && item.hasPermissison || item.isCheck"
              v-if="!isListGroup || isListGroup && i.isDropdownVisible"
              :disabled="isAdmin && !item.isEditableAdmin"
              @change="selectItem(item)"
            )
            svg.icon-dropdown(v-if="item.permissionListHasValue" :class="item.isDropdownVisible ? 'rotateArrow' : '' " @click="toggleDropdown(item)")
              use(xlink:href="#icon-arrow-down")

          .item-row.permissions(v-for="subItem in item.permissionList")
            CustomCheckbox.item-item-check.m-top-20(
              id="list-checkbox-item-all"
              v-if="item.isDropdownVisible && !(/^(DefineSupplierAsGlobal|DefineSupplierAsLocal)$/.test(subItem.permissionKey))"
              :label="$t('Permission.Menu_'+subItem.permissionKey)"
              :checked="item.isCheck && subItem.isCheck"
              :disabled="!item.isCheck"
              @change="selectItem(subItem)"
            )

            CustomRadio.item-item-check.m-top-20(
              v-if="item.isDropdownVisible && /^(DefineSupplierAsGlobal|DefineSupplierAsLocal)$/.test(subItem.permissionKey)"
              :label="$t('Permission.Menu_'+subItem.permissionKey)"
              :checked="checkGlobalSupplier(subItem, item)"
              @change="selectItem(subItem, item.permissionList)"
              :disabled="!item.isCheck"
            )  
</template>

<script>

export default {
  name: 'CustomCheckboxList',

  data () {
    return {
      openDropdown: false,
      renderComponent: true,
      listIsVisible: true
    }
  },

  props: {
    categoryList: {
      type: Array,
      required: false
    },
    subList: {
      type: Array,
      required: false
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    selectedItems: {
      type: Array,
      default: () => []
    },
    isListGroup: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    checkAll (isChecked, item) {
      this.$emit('selectAll', {isChecked, item})
      this.forceRerender()
    },
    selectItem (item, permissionList) {
      this.$emit('selectItems', item, permissionList)
      this.forceRerender()
    },
    isCheckAll (item) {
      if (item.permissionList) {
        return item.permissionList.every(i => i.isCheck)
      } else if (item.reports) {
        return item.reports.every(i => i.isCheck)
      } else if (item.listPermissionList) {
        return item.listPermissionList.every(i => i.isCheck)
      } else if (item.pricePermissionList) {
        return item.pricePermissionList.every(i => i.isCheck)
      }
    },
    toggleDropdown (item) {
      item.isDropdownVisible = !item.isDropdownVisible
      this.forceRerender()
    },
    forceRerender () {
      this.renderComponent = false
      this.$nextTick(() => {
        this.renderComponent = true
      })
    },
    checkGlobalSupplier (subItem, item) {
      if (
          item.isCheck &&
          item.permissionList.every(i => !i.isCheck) && 
          subItem.permissionKey === 'DefineSupplierAsGlobal'
        ) {
        subItem.isCheck = true
        if (subItem.isCheck) this.selectItem(subItem, item.permissionList)
        return true
      }
      else if (item.isCheck && subItem.isCheck) {
        subItem.isCheck = true
        return true
      }
      else return false
    }
  }

}
</script>

<style lang="scss" scoped>

.item {
  display: flex;
  justify-content: flex-start;
}
.main-categories {
  font-weight: $font-weight-bold;
  color: black;

}
.permissions {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
}
:deep() .label {
  display: unset !important;
  align-items: unset !important;
  color: black !important
}
.icon-dropdown {
  width: 20px;
  margin-left: 20px;
  position: relative;
  height: 30px;
  color: $color-success;
  margin-top: 15px;
  cursor: pointer;
  transition: all 0.5s;
  &-custom {
    @extend.icon-dropdown;
    margin-top: 5px
  }
}

.with-dropdown {
  display: flex;
  position: relative;
}

.rotateArrow {
  transform: rotate(180deg);
}
</style>
