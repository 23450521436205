<template lang="pug">
div
  .custom-list-checkbox
    .item-content
      .MiniPopup-tr.item-row
        CustomCheckbox.item-item-check(
        id="list-checkbox-select-all"
        :checked="isAllSelected"
        :label="allLabel"
        @change="toggleAll"
        :disabled="disabled")

      .MiniPopup-tr.item-row(
      v-for="(item, index) in items"
      v-if="item.isSearch")
        CustomCheckbox.item-item-check(
        :id="`list-checkbox-item-${id}-${index}`"
        :checked="item.isChecked"
        :label="item.name"
        @change="changeSelectbox(item)"
        :disabled="disabled")

        Button(
          v-if="item.isSearch && item.defaultStore && item.isChecked && withStar",
          type="button",
          size="small",
          :clearVariant="true"
          @click="changeDefaultStore(item)")
          svg.icon-vote-star-filled
            use(xlink:href="#icon-vote-star-filled")

        Button(
          v-if="item.isChecked && !item.defaultStore && withStar"
          type="button"
          size="small",
          :clearVariant="true"
          @click="changeDefaultStore(item)")
          svg.icon-vote-star-empty
            use(xlink:href="#icon-vote-star-empty")
</template>

<script>

export default {
  name: 'CustomListCheckbox',


  props: {
    id: {
      type: String,
      required: true
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    withStar: {
      type: Boolean,
      default: true
    },
    items: {
      type: Array,
      default () {
        return []
      }
    },
    allLabelText: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Array,
      default () {
        return []
      }
    },

    selectedHead: {
      type: Array,
      default () {
        return []
      }
    },

    allLabel: {
      type: String,
      default () {
        return ''
      }
    },

    listHeader: {
      type: String,
      default: ''
    },

    disabledList: {
      type: Array,
      default () {
        return []
      }
    },

    usePermissionTranslation: {
      type: Boolean,
      default: true
    },

    showToggleAll: {
      type: Boolean,
      default: false
    },

    hasSubPermission: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    isAllSelected () {
      const checkedList = this.items.filter(item => {
        return item.isChecked && item.isSearch
      }).length

      const searchList = this.items.filter(item => {
        return item.isSearch
      }).length

      return searchList === checkedList
    }
  },

  methods: {
    selectItem (item, isChecked) {
      this.$emit('delegateCheckItem', { item, isChecked })
    },


    changeSelectbox (item) {
      this.$emit('delegateCheckItem', { item })
    },

    toggleAll (isChecked, item) {
      this.$emit('delegateCheckAll', !this.hasSubPermission ? isChecked : {item, isChecked})
    },

    changeDefaultStore (item) {
      this.$emit('delegateChangeDefaultStore', item)
    }
  }
}
</script>

<style scoped lang="scss">

  .custom-list-checkbox {
    position: relative;
  }

  .item {

    &-header {
      padding: 0 10px 10px 10px;
      font-size: $font-size-normal;
      font-weight: $font-weight-normal;
    }

    &-row {
      padding: 0;

      label {
        display: block;
        width: 100%;
        padding: 16px 10px;
        color: $color-dark;
      }
      &-sub-items {
        padding-left: 40px;
        label {
          padding: 10px 0;
        }
      }
    }

    &-item-check {
      margin-right: 10px;
    }

    &-footer {
      padding: 10px;
    }
  }
  .item-row {

    .icon-vote-star-filled,
    .icon-vote-star-empty {
      width: 19px;
      height: 17px;
      margin: 15px;
    }

    .icon-vote-star-empty {
      display: none;
    }

    &:hover {

      .icon-vote-star-empty {
        display: initial;
      }
    }
  }
  .subItem {
    display: none;
  }
  .subMargin {
    margin-left: 40px!important;
  }
  .subMain {
    display: flex;
    align-items: center;
    label {
      width: fit-content;
    }
  }
  .icon-dropdown {
    color: $color-success;
    width: 18px;
    height: 10px;
    cursor: pointer;
    transition: all 0.5s;
    &.openDropdown {
      transform: rotate(180deg);
    }
  }
</style>
